export function getAccessToken () {
    const accessToken  = localStorage.getItem('Token')
    console.log("accessToken",accessToken)
    if (accessToken != null){
        const realAccessToken = accessToken?.split("_ts")
        if(realAccessToken.length < 1) return false
        
        return realAccessToken[0]
    }
    return null
    

}