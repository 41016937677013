import React, { useEffect, useState, useRef } from "react";
import { Route, Routes, HashRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider, useDispatch } from "react-redux";
import { validateAuth } from "./store/auth/auth.action";
import { HelmetProvider } from "react-helmet-async";

import Loadable from "react-loadable";
//import loadable from '@loadable/component'
import configureStore from "./store";
import StatisticsLayout from "./components/StatisticsLayout";
import ReactGA from 'react-ga4';
ReactGA.initialize('G-CTBVH9QL4E');

const { store, persistor } = configureStore();

const Home = Loadable({
  loader: () => import("./pages/home"),
  loading: () => null,
});

const NavbarMenu = Loadable({
  loader: () => import("./components/navbarMenu"),
  loading: () => null,
});

// ----- adding routes for parking status page and all pages under statistic menu -----

const App = () => {
  const [Auth, setAuth] = useState(null);
  const [token, setToken] = useState("");
  const [toggleLoadToken, set_toggleLoadToken] = useState(false);
  const dispatch = useDispatch();

  const sessionCalc = () => {
    console.log("aaa token", token);
    let result = true;
    if (token == null) {
      console.log("token null", token);
      return true;
    }
    let arrToken = token.split("_ts");
    if (arrToken.length == 1) {
      console.log("arrToken length 1", arrToken);
      result = true;
    } else {
      let expireTime = new Date(parseInt(arrToken[1] * 1000));
      console.log("expireTime", expireTime);
      console.log("new Date().getTime()", new Date());
      let difTS = new Date().getTime() - expireTime.getTime();
      //console.log("Login Times : ", difTS / (1000 * 60), "mins")
      // if (difTS / (1000 * 60) > 60 * 24) {
      //   console.log("arrToken expire", arrToken)
      //   result = true;
      // } else {
      //   result = false;
      // }
      //console.log("difTS",difTS/ (1000 * 60))
      if (difTS > 0) {
        console.log("arrToken expire", arrToken);
        result = true;
      } else {
        result = false;
      }
    }
    return result;
  };

  const curTime = useRef(new Date());

  const [idleTrig, idleTrigger] = useState({});
  const [selectedBA, setSelectBA] = useState({
    bacode: "1102",
    pwacode: "5542017",
    name: "กปภ.สาขาอ้อมน้อย",
    lat: "13.750294",
    lng: "100.297432",
    zone: "1",
    region: "4",
  });
  const [routePage, set_routePage] = useState("/");

  useEffect(() => {
    let sessionExpired = sessionCalc();
    console.log("sessionExpired", sessionExpired);
    if (!sessionExpired) {
      setAuth(true);
    } else {
      setAuth(false);
      //LogoutAction()
      //console.log(window.location)
    }
  }, [token]);

  useEffect(() => {
    let a = sessionCalc();
    //console.log("a", a)
  });
  useEffect(() => {
    dispatch(
      validateAuth((token, udata, uprofile) => {
        //console.log("data", data)
        setToken(token);
        if (token) {
          if (uprofile != null || uprofile != undefined) {
            //console.log("uprofile", JSON.stringify(uprofile))
            //setSelectProfile(uprofile);
          }
          //setAuth(true);
        }
      })
    );
  }, []);

  return (
    <HelmetProvider>
      <HashRouter>
        <NavbarMenu />
        <Routes>
          {/* <Route path="/" element={<Home />} exact /> */}
          <Route path="/" element={<Home />} >
              <Route path=":scrollTarget" element={<Home />}>
                  <Route index element={<Home />} />
              </Route>
            </Route>
        </Routes>
      </HashRouter>
    </HelmetProvider>
  );
};

const MyRoutes = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
};

export default MyRoutes;
